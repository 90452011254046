<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <b>Kierunek: {{ item.name.length > 80 ? item.name.substring(0, 80)+'...' : item.name }}</b>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-tabs
        :key="activeSettingsTabFromStore"
        v-model="tabActive"
        background-color="primary"
        dark
      >
        <v-tab
          v-for="itemS in getEvaluationsByUserIdUniqueSemesters(item.cid)"
          :key="itemS.id"
          v-if="
          (
            !(itemS.semester == 1 && itemS.date == '2024-03-01') &&
            !(itemS.semester == 2 && itemS.date == '2024-10-01') &&
            !(itemS.semester == 3 && itemS.date == '2025-03-01')
          )
          &&
          (
            !(itemS.semester == 1 && itemS.date == '2024-10-01') &&
            !(itemS.semester == 2 && itemS.date == '2025-03-01') &&
            !(itemS.semester == 3 && itemS.date == '2025-10-01')
          )
          "
        >
          semestr {{ itemS.semester }} ({{itemS.date }})
        </v-tab>
        <v-tab
          v-else-if="(itemS.semester == 1 && itemS.date == '2024-03-01') || (itemS.semester == 1 && itemS.date == '2024-10-01')"
        >
          Nabór {{getEvaluationsByUserIdUniqueSemesters(item.cid)[0].date }}
        </v-tab>
        <v-tab>
          zaliczenie
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabActive">
        <v-tab-item
            v-for="item2 in getEvaluationsByUserIdUniqueSemesters(item.cid)"
            v-if="(
                  !(item2.semester == 1 && item2.date == '2024-03-01') &&
                  !(item2.semester == 2 && item2.date == '2024-10-01') &&
                  !(item2.semester == 3 && item2.date == '2025-03-01')
                )
                &&
                (
                  !(item2.semester == 1 && item2.date == '2024-10-01') &&
                  !(item2.semester == 2 && item2.date == '2025-03-01') &&
                  !(item2.semester == 3 && item2.date == '2025-10-01')
                )"
            :key="item2"
        >
          <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Nazwa przedmiotu
                  </th>
                  <th class="text-left">
                    Ocena
                  </th>
                  <th class="text-left">
                    Wykładowca
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="subject in getEvaluationsByUserIdAndEvaluation(item2.eid)"
                    :key="subject"
                >
                  <td>{{ subject.subject_name }}</td>
                  <td>
                    {{ subject.grade }}
                  </td>
                  <td>
                    {{ subject.professor_name }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
        </v-tab-item>
        <v-tab-item v-else-if="(item2.semester == 1 && item2.date == '2024-03-01') || (item2.semester == 1 && item2.date == '2024-10-01')">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Nazwa przedmiotu
                </th>
                <th class="text-left">
                  Ocena
                </th>
                <th class="text-left">
                  Wykładowca
                </th>
              </tr>
              </thead>
              <tbody v-for="item2 in getEvaluationsByUserIdUniqueSemesters(item.cid)">
              <tr
                  v-for="subject in getEvaluationsByUserIdAndEvaluation(item2.eid)"
                  :key="subject"
              >
                <td>{{ subject.subject_name }}</td>
                <td>
                  {{ subject.grade }}
                </td>
                <td>
                  {{ subject.professor_name }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-tab-item>
        <v-tab-item class="mt-4 ml-4">
          <v-row>
            <v-col class="pt-0 pb-0" cols="12" md="3" sm="6">
              <v-checkbox
                  v-model="getFinishInfoFromLoggedInByCourseName(item.name).thesis"
                  :label="`Praca końcowa`"
                  disabled
                  readonly
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 pb-0" cols="12" md="3" sm="6">
              <v-checkbox
                  v-model="getFinishInfoFromLoggedInByCourseName(item.name).practice"
                  :label="`Praktyki`"
                  disabled
                  readonly
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row v-if="getFinishInfoFromLoggedInByCourseName(item.name).promoterName">
            <v-col cols="12" md="3" sm="6">
              Promotor: {{ getFinishInfoFromLoggedInByCourseName(item.name).promoterName}}
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'StudentGradesCourse',
  data() {
    return {
      tabActive: 0,
    }
  },
  props: [
    'item',
    'getEvaluationsByUserIdUniqueSemesters'
  ],
  computed: {
    ...mapGetters({
      evaluationData: 'evaluations/getChosenEvaluationData',
      getFormDataHistory: 'history/getFormDataHistory',
      getStudentEvaluations: 'evaluations/getEvaluationsByUserId',
      getEvaluationsByUserIdUniqueSemester: 'evaluations/getEvaluationsByUserIdUniqueSemester',
      getEvaluationsByUserIdAndEvaluation: 'evaluations/getEvaluationsByUserIdAndEvaluation',
      getFinishInfoFromLoggedInByCourseName: 'users/getFinishInfoFromLoggedInByCourseName',
    }),
  },
  mounted() {
  }
}
</script>
<style>
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
}
</style>
